.ebody {
    margin: 0;
    padding: 0;
}

.eh2 {
    background: linear-gradient(#181415e0, #181415e0),
    url('../assets/img/drive.jpg');
    color: #fff;
    padding: 10px;
    font-size: 42px;
    line-height: 65px;
}

@media only screen and (max-width: 767px){
    .eh2 {
        font-size: 30px;
    }
}


.eul {
    list-style-type: none;
    padding: 0;
}

.eul li {
    margin: 10px 0;
}

.ehr {
    border: 0;
    height: 1px;
    background-color: #ccc;
    margin: 20px 0;
}