.payment label {
  font-weight: 600;
  color: #181415;
}

.payment button {
  padding: 8px 15px;
  border: none;
  outline: none;
  background: #181415;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}
