.booking__form input {
  border: none;
  border: 1px solid #dbd3e12a;
  padding: 8px 15px;
  color: #dbd3e1;
}

.booking__form {
  width: 47.5%;
}

.booking__form select {
  padding: 10px 15px;
  width: 100%;
  border: none;
  border: 1px solid #dbd3e12a;
  border-radius: 4px;
  color: #dbd3e1;
}

.time__picker:before {
  content: 'Journey Time';
  margin-right: 0.6em;
  color: #dbd3e1;
}

.textArea {
  padding: 10px 15px;
  width: 100%;
  border: none;
  border: 1px solid #dbd3e12a;
  border-radius: 4px;
  color: #dbd3e1;
}

.textArea:focus {
  outline: none;
}

.booking__form select:focus {
  outline: none;
}
