.service__box span i {
  font-size: 2.5rem;
  color: #f9a826;
}

.service__box h6:hover {
  color: #f9a826;
}

.service__box h6 {
  transition: 0.3s ease-in;
}

@media only screen and (max-width: 767px) {
  .service__box h6 {
    font-size: 1.1rem;
  }
}
