.contact__form {
  width: 47%;
}

@media only screen and (max-width: 1000px) {
  .contact__form {
    width: 100%;
  }
}
@media only screen and (max-width: 760px) {
  .contact__form {
    width: 47%;
  }
}
@media only screen and (max-width: 500px) {
  .contact__form {
    width: 100%;
  }
}

.contact__form input {
  border: none;
  border: 1px solid #dbd3e18d;
  padding: 8px 15px;
  color: #252327;
}

.contact__form textarea {
  border: none;
  border: 1px solid #dbd3e18d;
  padding: 8px 15px;
  color: #252327;
}

.contact__form textarea:focus {
  outline: none;
}