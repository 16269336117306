.time__line h6 span {
  background: #dbd3e1;
  color: rgba(255, 255, 255, 0.481);

  border-radius: 50%;
  padding: 5px 12px;
  text-align: center;
  margin-left: 5px;
  pointer-events: none;
}

.time__line h6 {
  color: #dbd3e1;
  cursor: pointer;
  position: relative;
  z-index: 201;
}

.active {
  background: #181415 !important;
  color: #fff !important;
}

.active2 {
  color: #181415 !important;
}

@media only screen and (max-width: 991px) {
  .time__line h6 {
    font-size: 1rem !important;
  }
  .time__line h6 span {
    padding: 3px 9px;
  }
}
