@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #181415;
  font-weight: 600;
  font-size: 35px;
}
h3,
h4 {
  font-size: 28px;
}
h5,
h6 {
  font-size: 22px;
}
a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: unset;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

section {
  padding: 50px 0;
}

.section__title {
  font-size: 35px;
  color: #181415;
  font-weight: 600;
}

.section__subtitle {
  font-size: 20px;
  color: #f9a826;
  font-weight: 600;
}

.section__description {
  font-size: 15px;
  color: #281f10;
  line-height: 30px;
}

.footer__bottom > .section__description, .footer__top .section__description {
  color: #fff;
}
.btn {
  padding: 8px 15px;
  border: none;
  outline: none;
  background: #181415;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}

.btn:hover {
  background: #181415;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .section__title {
    font-size: 30px;
  }

  .section__subtitle {
    font-size: 18px;
  }

  .section__description {
    font-size: 13px;
  }

  section {
    padding: 30px 0;
  }
}
