.login__form input {
  border: none;
  outline: none;
  color: #dbd3e1 !important;
  border-left: 1px solid #dbd3e12f;
  border-radius: 0;
  font-size: 14px;
  padding: 5px 15px;
}

.login__form span i {
  color: #dbd3e1;
  font-size: 1rem;
}

.login__form {
  border: 1px solid #dbd3e12f;
  padding: 5px 15px;
}

.login__btn {
  padding: 7px 25px;
  border: none;
  outline: none;
  background: #181415;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
}
