.paginationBttns {
  width: 80%;
  margin: auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
}

.paginationBttns a {
  padding: 5px 13px;
  background: #181415;
  color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
}

.car__sorting span {
  color: #181415;
  font-weight: 600;
}

.car__sorting select {
  border: 1px solid #dbd3e12a;
  padding: 8px 15px;
  color: #dbd3e1;
}

.car__sorting select:focus {
  outline: none;
}
